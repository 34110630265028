export class Debounce {
    constructor(debounceTime) {
        this.debounceTime = debounceTime;
        this.promise = null;
        this.resolvePromise = () => { };
        this.rejectPromise = () => { };
    }
    run(runnable) {
        this.internalReset();
        this.promise = new Promise((resolve, reject) => {
            this.resolvePromise = resolve;
            this.rejectPromise = reject;
        });
        if (this.debounceTime > 0) {
            this.timeBasedResyncHandle = setTimeout(() => {
                runnable();
                this.resolvePromise();
            }, this.debounceTime);
        }
        else {
            runnable();
            this.resolvePromise();
            this.timeBasedResyncHandle = null;
        }
    }
    get isRunning() {
        return this.timeBasedResyncHandle != null;
    }
    get onRunFinished() {
        return this.promise;
    }
    internalReset() {
        if (this.timeBasedResyncHandle != null) {
            clearTimeout(this.timeBasedResyncHandle);
            this.timeBasedResyncHandle = null;
            this.promise = null;
        }
    }
    reset() {
        if (this.promise) {
            this.promise.catch(() => {
                // We have to catch the rejection here because otherwise
                // we always have to catch the rejection within the components
            });
            this.rejectPromise();
        }
        this.internalReset();
    }
}
